<script setup>
import GridView from './partials/grid-view';
import TableView from './partials/table-view';
import ListingListFilterElement from './partials/filters/filter-element';
import ListingListCategoriesSidebar from './partials/categories-sidebar';
import ListingListReasonsTopbar from './partials/reasons-topbar';
import DesktopListingsSortButton from './partials/sort-button';
import DesktopListingsAddToFavoriteSearch from './partials/add-to-favorite-search';
import DesktopListingFavoriteSearch from './partials/favorite-search';
import { useMetaStore } from '~/stores/meta.store';
import { useFiltersStore } from '~/stores/filters.store';
import { ref, watch, computed } from 'vue';
import { useCookie, useRoute, useRouter, useRuntimeConfig } from 'nuxt/app';

const props = defineProps({
    data: {
        type: Object
    },
    fetchData: {
        type: Function,
        required: true,
    },
});
const metaStore = useMetaStore();
const config = useRuntimeConfig();

const isGrid = useCookie(
    'isGrid',
    {
        default: () => false,
        watch: true,
        sameSite: 'strict'
    }
);

const page = ref(Number(props.data?.pagination?.currentPage) || 1);
const pageData = ref(props.data);

const route = useRoute();
const router = useRouter();

const changeIsGrid = (isGridValue) => {
    isGrid.value = isGridValue;
}

watch(() => props.data, () => pageData.value = props.data);

watch(page, async (newPage) => {
    await router.push({
        query: { ...route.query, page: newPage.toString() },
    })
});

watch(() => route.query, async () => {
    // Scroll to top
    window.scroll({ behavior: 'smooth', top: 0 });

    // Fetch and update new page data
    const { data } = await props.fetchData(route.query);

    if (data) {
        pageData.value = data;
    }
});

const breadcrumbSeo = props.data.data.breadcrumb?.map((b, i) => ({
    "@type": "ListItem",
    "@position": i + 1,
    "name": b.name,
    "item": `${config.public.websiteUrl}/${b.url}`
}))

useSchemaOrg({
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    "itemListElement": breadcrumbSeo
})

metaStore.setMeta(pageData.value.meta);

const filtersStore = useFiltersStore();
await filtersStore.fetchFilters(pageData.value.data.category.id);
filtersStore.selected_filters = pageData?.value.selected_filters;

const listingCount = computed(() => filtersStore.listings_count);

</script>
<template>
    <LayoutAppHead></LayoutAppHead>
    <NuxtLayout name="desktop-default">
        <div class="container mx-auto max-w-7xl px-4 sm:px-2 lg:px-8 mb-8">
            <nav class="flex my-4" aria-label="Breadcrumb">
                <breadcrumb :data="pageData.data.breadcrumb"></breadcrumb>
                <DesktopListingFavoriteSearch></DesktopListingFavoriteSearch>
            </nav>

            <div class="grid grid-cols-4 gap-4 mx-0">
                <div class="side">
                    <ListingListCategoriesSidebar :categories_in_sidebar="pageData.categories_in_sidebar" :filtersStore="filtersStore" />
                    <div class="bg-white rounded-xl border my-2 sticky top-5">
                        <template v-for="feature in filtersStore.filter.features" :key="`elm_${feature.key}`">
                            <ListingListFilterElement :filter="feature" :filtersStore="filtersStore"
                                :countryId="pageData.data.country.id" />
                        </template>
                        <button class="search-btn-filter sticky bottom-0">
                            <NuxtLink :to="localePath(`/${filtersStore.filter_url}`)">{{ $t('client.show_listings', { count: listingCount || 0 }) }}</NuxtLink>
                        </button>
                    </div>
                </div>
                <div class="col-span-3">
                    <div class="p-3 bg-white rounded-xl border">
                        <div class="flex lg:flex-row md:flex-col lg:items-center md:items-start justify-between">
                            <div>
                                <div class="font-normal text-sm text-gray-500" v-if="pageData.data.property_for_in_location_count" v-html="pageData.data.property_for_in_location_count"></div>
                                <div class="min-w-14 mx-auto">
                                    <div class="font-normal text-sm text-yellow-800 p-3 bg-yellow-100 rounded-md" v-if="pageData.data.no_result" v-html="pageData.data.no_result"></div>
                                </div>
                            </div>
                            <div class="flex items-center gap-1">
                                <button class="p-1 rounded cursor-pointer" :class="isGrid ? 'text-gray-400 bg-gray-200' : 'bg-indigo-900 text-white'" @click="changeIsGrid(false)">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5">
                                        <path fill-rule="evenodd" d="M2.625 6.75a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Zm4.875 0A.75.75 0 0 1 8.25 6h12a.75.75 0 0 1 0 1.5h-12a.75.75 0 0 1-.75-.75ZM2.625 12a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0ZM7.5 12a.75.75 0 0 1 .75-.75h12a.75.75 0 0 1 0 1.5h-12A.75.75 0 0 1 7.5 12Zm-4.875 5.25a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Zm4.875 0a.75.75 0 0 1 .75-.75h12a.75.75 0 0 1 0 1.5h-12a.75.75 0 0 1-.75-.75Z" clip-rule="evenodd" />
                                    </svg>
                                </button>
                                <button class="p-1 rounded cursor-pointer" :class="!isGrid ? 'text-gray-400 bg-gray-200' : 'bg-indigo-900 text-white'" @click="changeIsGrid(true)">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5">
                                        <path fill-rule="evenodd" d="M3 6a3 3 0 0 1 3-3h2.25a3 3 0 0 1 3 3v2.25a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V6Zm9.75 0a3 3 0 0 1 3-3H18a3 3 0 0 1 3 3v2.25a3 3 0 0 1-3 3h-2.25a3 3 0 0 1-3-3V6ZM3 15.75a3 3 0 0 1 3-3h2.25a3 3 0 0 1 3 3V18a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-2.25Zm9.75 0a3 3 0 0 1 3-3H18a3 3 0 0 1 3 3V18a3 3 0 0 1-3 3h-2.25a3 3 0 0 1-3-3v-2.25Z" clip-rule="evenodd" />
                                    </svg>
                                </button>
                                <desktop-listings-add-to-favorite-search></desktop-listings-add-to-favorite-search>
                                <desktop-listings-sort-button></desktop-listings-sort-button>
                            </div>
                        </div>
                        <ListingListReasonsTopbar :reasons="pageData.reasons"></ListingListReasonsTopbar>
                        <GridView :listings="pageData.data.listings" v-if="isGrid && pageData.data.listings"></GridView>
                        <TableView :listings="pageData.data.listings" :list_view_features="pageData.data.list_view_features" v-if="!isGrid && pageData.data.listings" :fetchData="fetchData"></TableView>

                        <div class="flex justify-center mt-4">
                            <w-pagination v-model="page" :page-count="pageData?.pagination?.perPage" :total="pageData?.pagination?.total" size="md" :active-button="{ variant: 'solid', color: 'indigo' }" />
                        </div>

                    </div>
                    <div v-if="pageData.data.listing_page">
                        <h2 class="text-4xl font-bold text-indigo-900 my-5">{{ pageData.data.listing_page.title }}</h2>
                        <div v-html="pageData.data.listing_page.contents"></div>
                    </div>
                </div>
            </div>
        </div>
    </NuxtLayout>
</template>
